import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FoundationWrapper from "./../../components/FoundationWrapper";
import Perspectives from "./../../components/Perspectives";
import { FoundationFrontText } from "./../../components/FoundationStyles/elements";
// import { H3Strong } from "./../../components/Typography";

const FoundationFrontPage = () => {
  const frontPageQuery = useStaticQuery(graphql`
    {
      craftFoundationFrontFoundationFrontEntry {
        richTextField
        perspectives {
          slug
          title
          typeHandle
          ... on Craft_articles_words_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_videos_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_images_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_sounds_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_event_event_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).craftFoundationFrontFoundationFrontEntry;
  // TODO: feed the selected programme to <FoundationWrapper /> as selectedProgram
  return (
    <FoundationWrapper
      useStayInformed={true}
      selected={false}
      title={
        <React.Fragment>
          Alserkal
          <br /> Arts Foundation
        </React.Fragment>
      }
    >
      <FoundationFrontText
        dangerouslySetInnerHTML={{ __html: frontPageQuery.richTextField }}
        style={{ paddingBottom: "100px" }}
      />
      <div
        style={{
          backgroundColor: "var(--white)",
          padding: "50px 100px 0 100px",
        }}
      >
        <Perspectives
          tiles={frontPageQuery.perspectives}
          title={"Recent programmes"}
          bottomMargin={"80px"}
        />
      </div>
    </FoundationWrapper>
  );
};

export default FoundationFrontPage;
